import React, { useState, useContext, useEffect } from 'react'
import {
  Avatar,
  Button,
  Tabs,
  Popconfirm,
  Icon,
  Tooltip,
  Modal,
  message,
  DatePicker,
  Row,
  Col
} from 'antd'
import SimpleList from '../override/SimpleList'
import ShareDataModal from '../modals/SharingDataModal'
import CustomIcon from '../override/Icon'
import { ChangePermissionModal } from '../modals/ChangePermissionModal'
import api from '../../lib/api'
import { useDispatch, useSelector } from 'react-redux'
import VaultContext from './../../contexts/VaultContext'
import { fetchDeputies } from './../../features/deputies/deputiesSlice'
import { onError } from '../../lib/sentry'
import { DATE_FORMAT } from '../../share/Constants'
import { disabledDate, renderDateTime, showLog } from '../../share/helpers'
import { StatusText } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import ShowLogIcon from '../common/ShowLogIcon'
const { TabPane } = Tabs

export const SharingDetails = ({
  users,
  selectedDoc,
  viewDetail,
  setSelectedDoc,
  documentActivities,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}) => {
  const { userId } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const [shareDataModalVisible, setShareDataModalVisible] = useState(false)
  const [selectedId, setSelectedId] = useState()
  const [permissionModalVisible, setPermissionModalVisible] = useState(false)
  const [permission, setPermission] = useState()
  const [email, setEmail] = useState()
  const [logs, setLogs] = useState([])
  const { activeDocuments } = useSelector(state => state.documents)
  const { activeContacts } = useSelector(state => state.contacts)
  const dispatch = useDispatch()

  const changePermission = record => {
    setPermissionModalVisible(true)
    setSelectedId(selectedDoc.id)
    setPermission(record.permission)
    setEmail(record.email)
  }

  useEffect(() => {
    if (selectedDoc && documentActivities?.length) {
      // if (selectedDoc.type === 'folder') {
      //   const logs = documentActivities.filter(
      //     d =>
      //       d.Detail?.to?.includes(selectedDoc.path) ||
      //       d.Detail?.from?.includes(selectedDoc.path) ||
      //       d.Detail?.in?.includes(selectedDoc.path) ||
      //       d.DocumentId === selectedDoc.id
      //   )
      //   setLogs(logs)
      // } else {
      //   const logs = documentActivities.filter(
      //     d => d.DocumentId === selectedDoc.id
      //   )
      //   setLogs(logs)
      // }
      const logs = documentActivities.filter(
        d => d.DocumentId === selectedDoc.id
      )
      setLogs(logs)
    }
  }, [documentActivities, selectedDoc])

  const revoke = async email => {
    try {
      const linkedItems = [...activeContacts, ...activeDocuments]?.filter(
        i =>
          i.documents?.includes(selectedDoc.id) ||
          i.contacts?.includes(selectedDoc.id)
      )

      const data = {
        email,
        recordId: selectedDoc.id
      }

      if (linkedItems?.length) {
        Modal.confirm({
          title: 'Revoke linked item',
          content: `${linkedItems.length} item(s) will be unshared. Are you want to revoke?`,
          onCancel: () => {},
          onOk: async () => {
            await Promise.all(
              linkedItems.map(
                async item =>
                  await api.revokeSharing(
                    userId,
                    JSON.stringify({
                      email,
                      recordId: item._id
                    })
                  )
              )
            )
            await api.revokeSharing(userId, JSON.stringify(data))
            dispatch(fetchDeputies(userId))
            message.success('Successfully revoke sharing!')
            setSelectedDoc()
          }
        })
      } else {
        await api.revokeSharing(userId, JSON.stringify(data))
        dispatch(fetchDeputies(userId))
        setSelectedDoc()
        message.success('Successfully revoke sharing!')
      }
    } catch (error) {
      onError(error)
    }
  }

  return (
    <>
      <Tabs
        defaultActiveKey="Sharing"
        style={{ width: 300 }}
        tabBarExtraContent={
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => setShareDataModalVisible(true)}
            type="primary"
            icon="share-alt"
          >
            Share{' '}
          </Button>
        }
      >
        <TabPane tab="Sharing" key="Sharing">
          <SimpleList
            data={users}
            itemKey="email"
            icon={user => (
              <Avatar
                size={36}
                style={{
                  backgroundColor: user.background,
                  fontSize: 14,
                  color: '#fff'
                }}
              >
                {user.shortName}
              </Avatar>
            )}
            mainInfo={contact => contact.fullName}
            subInfo={contact =>
              contact.id ? contact.permission : contact.email
            }
            action={contact => (
              <>
                <Tooltip
                  title="Change permission"
                  arrowPointAtCenter
                  placement="topRight"
                >
                  <Icon
                    theme="twoTone"
                    type="edit"
                    onClick={() => changePermission(contact)}
                  />
                </Tooltip>

                <Popconfirm
                  title="Are you sure to revoke sharing?"
                  onConfirm={() => revoke(contact.email)}
                  okText="Yes"
                  cancelText="No"
                  arrowPointAtCenter
                  placement="bottomRight"
                >
                  <Tooltip title="Revoke sharing" arrowPointAtCenter>
                    <CustomIcon
                      type="trash"
                      style={{ color: 'red', fontSize: 12 }}
                    />
                  </Tooltip>
                </Popconfirm>
              </>
            )}
          />
        </TabPane>
        <TabPane tab="Activities" key="activities">
          <Row gutter={10} style={{ marginLeft: 0, marginBottom: 20 }}>
            <Col span={12}>
              <DatePicker
                onChange={setStartDate}
                placeholder={'FROM'}
                value={startDate}
                disabledDate={startDate => disabledDate(startDate, endDate)}
                format={DATE_FORMAT}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                onChange={setEndDate}
                placeholder={'TO'}
                value={endDate}
                disabledDate={endDate => disabledDate(startDate, endDate)}
                format={DATE_FORMAT}
              />
            </Col>
          </Row>
          {logs.map(a => (
            <div
              key={a.Id}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 20
              }}
            >
              <div
                style={{
                  height: 36,
                  width: 36,
                  minWidth: 36,
                  backgroundColor: theme.dark3,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 14
                }}
              >
                <ShowLogIcon item={a} />
              </div>
              <div>
                {showLog(a)}
                <StatusText color={theme.dark2}>
                  {renderDateTime(a.Time)}
                </StatusText>
              </div>
            </div>
          ))}
        </TabPane>
      </Tabs>
      <ShareDataModal
        visible={shareDataModalVisible}
        setVisible={setShareDataModalVisible}
        selectedDoc={selectedDoc}
        viewDetail={viewDetail}
        setSelectedDoc={setSelectedDoc}
      />
      <ChangePermissionModal
        visible={permissionModalVisible}
        setVisible={setPermissionModalVisible}
        selectedId={selectedId}
        permission={permission}
        setPermission={setPermission}
        email={email}
        setSelectedId={setSelectedId}
        setSelectedDoc={setSelectedDoc}
      />
    </>
  )
}
