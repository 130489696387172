import React, { useState, useContext, useEffect } from 'react'
import {
  Layout,
  Tooltip,
  Icon,
  Input,
  Modal,
  message,
  Divider,
  Empty,
  Menu,
  Dropdown
} from 'antd'
import { H4 } from '../override/Typography'
import { useTranslation } from 'react-i18next'
import SimpleHeader from '../override/SimpleHeader'
import Button from '../override/Button'
import CustomTable from '../override/CustomTable'
import VaultContext from '../../contexts/VaultContext'
import LocationModal from './LocationModal'
import { unlinkDocumentFormLocation, deleteLocation } from '../../lib/pouchDb'
import { useSelector, useDispatch } from 'react-redux'
// import ImportDropdown from '../assets-liabilities/ImportDropdown'
import {
  checkPermission,
  search,
  exportLocationsToPDF,
  exportLocationsToExcel
} from '../../share/helpers'
import { fetchLocations } from './../../features/location/LocationSlice'
import { uniq } from 'lodash'
import ShowImage from '../common/ShowImage'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'

const { Content } = Layout

export default function Location() {
  const [visible, setVisible] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState({})

  const [fillteredLocations, setFillteredLocations] = useState([])
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { userId, masterKey, isReadonly, recordIds, permissions, fullName } =
    useContext(VaultContext)
  const { activeLocations } = useSelector(state =>
    isReadonly ? state.otherLocations : state.locations
  )

  const [addS3Change] = useMutation(createS3Change)

  useEffect(() => {
    setFillteredLocations(activeLocations)
  }, [activeLocations])

  const handleDeleteLocation = record => {
    Modal.confirm({
      title: t('CONFIRM_DELETE'),
      content: <>{t('CONFIRM_DELETE_ITEM_MSG')}</>,
      async onOk() {
        try {
          await deleteLocation(userId, record, masterKey)
          await unlinkDocumentFormLocation(
            userId,
            record._id,
            masterKey,
            'documents'
          )
          dispatch(fetchLocations(userId, masterKey))
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message: 'locations, documents',
              userId: userId
            }
          })
          message.success(t('Successfully deleted location'))
        } catch (error) {
          message.error(t('Failed to delete location'))
        }
      },
      onCancel() {}
    })
  }

  const searchLocation = value => {
    const searchResult = search(
      activeLocations,
      ['level1', 'level2', 'level3'],
      value
    )

    setFillteredLocations(searchResult)
  }

  const columns = [
    // {
    //   key: 'no',
    //   width: 200,
    //   dataIndex: 'no',
    //   title: <span className="dragHandler">{t('No')}</span>,
    //   sorter: (a, b) => a.no.localeCompare(b.no)
    // },
    // {
    //   key: 'name',
    //   width: 200,
    //   dataIndex: 'name',
    //   title: <span className="dragHandler">{t('Name')}</span>
    // },
    // {
    //   key: 'division',
    //   width: 250,
    //   dataIndex: 'division',
    //   title: <span className="dragHandler">{t('Division')}</span>
    // },
    {
      key: 'level1',
      // colSpan: 3,
      // width: 150,
      dataIndex: 'level1',
      title: t('Level 1'),
      filters: uniq(
        activeLocations.filter(lc => lc.level1).map(lc => lc.level1)
      ).map(value => {
        return {
          value: value,
          text: value
        }
      }),
      onFilter: (value, record) => record.level1.indexOf(value) === 0,
      sorter: (a, b) => a.level1?.localeCompare(b.level1),
      render: (text, record) =>
        text && (
          <>
            <ShowImage recordId={record._id} isListLocation={true} level={1} />
            <span style={{ marginLeft: 5 }}>
              {text.length > 20 ? (
                <Tooltip placement="bottom" title={text}>
                  <span>{text.slice(0, 20).concat('...')}</span>
                </Tooltip>
              ) : (
                text
              )}
            </span>
          </>
        )
    },
    {
      key: 'level2',
      // colSpan: 0,
      // width: 150,
      dataIndex: 'level2',
      title: 'Level 2',
      filters: uniq(
        activeLocations.filter(lc => lc.level2).map(lc => lc.level2)
      ).map(value => {
        return {
          value: value,
          text: value
        }
      }),
      onFilter: (value, record) => record.level2.indexOf(value) === 0,
      sorter: (a, b) => a.level2?.localeCompare(b.level2),
      render: (text, record) =>
        text && (
          <>
            <ShowImage recordId={record._id} isListLocation={true} level={2} />
            <span style={{ marginLeft: 5 }}>
              {text.length > 20 ? (
                <Tooltip placement="bottom" title={text}>
                  <span>{text.slice(0, 20).concat('...')}</span>
                </Tooltip>
              ) : (
                text
              )}
            </span>
          </>
        )
    },
    {
      key: 'level3',
      // colSpan: 0,
      // width: 150,
      dataIndex: 'level3',
      title: 'Level 3',
      filters: uniq(
        activeLocations.filter(lc => lc.level3).map(lc => lc.level3)
      ).map(value => {
        return {
          value: value,
          text: value
        }
      }),
      onFilter: (value, record) => record.level3.indexOf(value) === 0,
      sorter: (a, b) => a.level3?.localeCompare(b.level3),
      render: (text, record) =>
        text && (
          <>
            <ShowImage recordId={record._id} isListLocation={true} level={3} />
            <span style={{ marginLeft: 5 }}>
              {text.length > 20 ? (
                <Tooltip placement="bottom" title={text}>
                  <span>{text.slice(0, 20).concat('...')}</span>
                </Tooltip>
              ) : (
                text
              )}
            </span>
          </>
        )
    },
    {
      key: 'actions',
      width: 200,
      dataIndex: 'actions',
      render: (text, record) =>
        (!isReadonly || checkPermission(permissions, record._id)) && (
          <div
            style={{ textAlign: 'end', minWidth: 40 }}
            onClick={e => e.stopPropagation()}
          >
            <Tooltip
              title={t('Edit location')}
              arrowPointAtCenter
              placement="topRight"
            >
              <Icon
                theme="twoTone"
                type="edit"
                onClick={() => {
                  setIsEditMode(true)
                  setVisible(true)
                  setSelectedLocation(record)
                }}
              />
            </Tooltip>{' '}
            <Divider type="vertical" />
            <Tooltip
              title={t('Remove location')}
              arrowPointAtCenter
              placement="topRight"
            >
              <Icon
                onClick={() => handleDeleteLocation(record)}
                theme="twoTone"
                twoToneColor="#eb4444"
                type="delete"
              />
            </Tooltip>
          </div>
        )
    }
  ]

  const exportMenu = (
    <Menu>
      <Menu.Item
        onClick={() =>
          exportLocationsToPDF(
            fullName,
            activeLocations.filter(
              lc => !recordIds?.length || recordIds.includes(lc._id)
            ),
            userId,
            masterKey
          )
        }
      >
        {t('TO_PDF')}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          exportLocationsToExcel(
            fullName,
            activeLocations.filter(
              lc => !recordIds?.length || recordIds.includes(lc._id)
            ),
            userId,
            masterKey
          )
        }
      >
        {t('TO_EXCEL')}
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Layout
        style={{
          height: '100%',
          padding: '0 20px 20px',
          backgroundColor: '#fff'
        }}
      >
        <SimpleHeader
          title={<H4 display="inline-block">{t('Location management')}</H4>}
          extra={
            <div className="table-actions">
              <Input.Search
                placeholder={t('ENTER_THE_SEARCH_KEYWORD')}
                allowClear
                onSearch={value => searchLocation(value)}
                className="search-asset-liability-input"
                style={{ marginRight: 20 }}
              />

              <div className="actions-group">
                {!isReadonly && (
                  <>
                    <Button
                      className="tour-asset-group-buttons"
                      onClick={() => setVisible(true)}
                    >
                      {t('CREATE_NEW')}
                    </Button>
                    {/* <ImportDropdown /> */}
                  </>
                )}

                <Dropdown overlay={exportMenu} trigger={['click', 'hover']}>
                  <Button>
                    {t('EXPORT')} <Icon type="down" />
                  </Button>
                </Dropdown>
              </div>
            </div>
          }
        />
        <Content>
          {fillteredLocations?.length ? (
            <CustomTable
              rowKey="_id"
              columns={columns}
              dataSource={[...fillteredLocations]
                .sort((a, b) => a.level1.localeCompare(b.level1))
                .filter(lc => !recordIds?.length || recordIds.includes(lc._id))}
            />
          ) : (
            <Empty />
          )}
        </Content>
      </Layout>
      <LocationModal
        selectedLocation={selectedLocation}
        visible={visible}
        setVisible={setVisible}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        setSelectedLocation={setSelectedLocation}
        onAddComplete={() => setSelectedLocation({})}
        locations={activeLocations}
      />
    </>
  )
}
