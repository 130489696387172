import React, { useState, useContext } from 'react'
import { Modal, Select, Form, message } from 'antd'
import api from '../../lib/api'
import { useDispatch } from 'react-redux'
import { SHARE_PERMISSIONS } from './../../share/Constants'
import FormItem from '../override/FormItem'
import VaultContext from './../../contexts/VaultContext'
import { fetchDeputies } from './../../features/deputies/deputiesSlice'

export const ChangePermissionModal = props => {
  const {
    visible,
    setVisible,
    selectedId,
    permission,
    setPermission,
    setSelectedId,
    email,
    setSelectedDoc
  } = props
  const { userId } = useContext(VaultContext)
  const [isSaving, setIsSaving] = useState()

  const dispatch = useDispatch()

  const handleSave = async () => {
    setIsSaving(true)
    try {
      const data = {
        email,
        id: selectedId,
        permission
      }

      await api.changePermission(userId, JSON.stringify(data))
      dispatch(fetchDeputies(userId))
      setSelectedDoc && setSelectedDoc()
      setIsSaving(false)
      message.success('Successfully changed permission!')
      handleCancel()
    } catch (error) {
      setIsSaving(false)
      message.error('Failed to change permission!')
    }
  }

  const handleCancel = () => {
    setVisible(false)
    setSelectedId()
    setPermission()
  }

  return (
    <Modal
      title="Change permission"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSave}
      className="add-deputy-modal"
      okButtonProps={{
        loading: isSaving
      }}
    >
      <Form>
        <FormItem label="Permission">
          <Select
            showAction={['click', 'focus']}
            allowClear
            onChange={e => setPermission(e)}
            value={permission}
          >
            {Object.values(SHARE_PERMISSIONS).map(p => (
              <Select.Option key={p} value={p}>
                {p}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  )
}
